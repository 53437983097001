import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../../../components/mdx/page";
import { Breadcrumbs } from "../../../../../components/widgets/breadcrumbs";
import { Aside } from "../../../../../components/widgets/aside";
import { Border } from "../../../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/company/newsletter/" text="Newsletter Archive" mdxType="Breadcrumbs" /> 
    <p>{`Hello TuringTrader Member,`}</p>
    <p>{`The year is off to a healthy start, and the S&P 500 rallied about 4.5% in January. At the same time, we can see that the economy is starting to slow, which is required to cool inflation. In particular, `}<a parentName="p" {...{
        "href": "/dashboard/indicators/"
      }}>{`industrial production and real sales dropped from previous peaks`}</a>{`. It is still too early to tell if it is possible to slow down the economy without hurting the stock market. We feel that `}<a parentName="p" {...{
        "href": "/dashboard/market-vane/"
      }}>{`Market Vane’s bearish positioning is still justified`}</a>{` in this environment.`}</p>
    <p>{`Throughout 2022, we retrofitted many strategies to use Buoy as their risk-off investment. While this was a necessary and valuable step, it also introduced some nuisances. In particular, monthly strategies now included a component with a monthly schedule. And while Buoy does not often change its allocations, making this more of a cosmetic issue, it was still a source of confusion.`}</p>
    <p>{`Today, we are fixing that. First, we have introduced new internal variants of Buoy, which rebalance on a monthly schedule. We have then retrofitted these strategies into `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-monthly/"
      }}>{`All-Stars Monthly`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin/"
      }}>{`Round Robin`}</a>{`. And while the overall performance is almost unchanged, both portfolios are now following a truly monthly schedule once again.`}</p>
    <p>{`You might have noticed by now that I am very committed to creating educational content. With my friend Evan Medeiros from The Trade Risk, I created a video last week explaining TuringTrader’s unique Monte Carlo simulations and how these charts help make better investing decisions. `}<a parentName="p" {...{
        "href": "https://www.youtube.com/live/P-K6im2vhJs"
      }}>{`Check it out!`}</a></p>
    <p>{`As always, I want to thank everybody who reached out over the past month with questions, comments, and suggestions. It’s an honor to have you on our site. If you like what we do, support our growth by sharing how `}<a parentName="p" {...{
        "href": "/"
      }}>{`TuringTrader`}</a>{` adds value for you with your friends.`}</p>
    <p>{`Stay tuned for further updates,`}<br />{`
All the best`}</p>
    <p>{`Felix`}<br />{`
--`}<br />{`
Felix Bertram`}<br />{`
Founder of TuringTrader.com`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      